import React from "react";
import { Typography, Button } from "antd";
import Lottie from "react-lottie";
import * as animationData from "./auctionAnimation.json";
export default function Auction() {
  const { Title, Paragraph } = Typography;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // vertical centering
          background: "white",
          height: "200px",
          width: "100%",
          maxWidth: "400px",
          margin: "0 auto", // center the container itself if needed
        }}
      >
        <Lottie options={defaultOptions} height={200} width={400} />
      </div>

      <Title level={4} style={{ color: "white" }}>
        {" "}
        Silent Auction is now available!
      </Title>
      <Paragraph
        style={{
          fontSize: 20,
          paddingBottom: 8,
          color: "white",
        }}
      >
        {" "}
        Don’t miss out on these incredible hotel stay deals—because they’re
        checking out fast!
      </Paragraph>
      <Button type="primary" style={{ fontSize: 18 }}>
        <a href="https://givebutter.com/c/btiXBI/auction/categories/33752/hotel-stay">
          Bid now!
        </a>
      </Button>
    </div>
  );
}
