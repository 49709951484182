import React from "react";
import { Result, Button, Typography } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import Auction from "./formComponents/auction";
const { Text, Paragraph } = Typography;
export default function SoldOut() {
  return (
    <div class="introduction-banner">
      <Result
        // icon={<SmileOutlined />}
        title={
          <Text style={{ color: "white" }}>
            Red Carpet Hospitality Gala is SOLD OUT!
          </Text>
        }
        subTitle={
          <Paragraph>
            <Paragraph style={{ color: "white" }}>
              Please be advised that the Red Carpet Hospitality Gala is SOLD
              OUT!!
            </Paragraph>
            <Paragraph style={{ color: "white" }}>
              If you missed out on tickets but are still interested in
              attending, please email Melanie McEvoy at
              melanie@mcevoyandassociates.com to be placed on a waitlist.
            </Paragraph>
            <Paragraph style={{ color: "white" }}>
              We look forward to seeing you on April 7th!
            </Paragraph>
          </Paragraph>
        }
      />
      <Auction />
    </div>
  );
}
