import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Home from "./page/home";
import Checkoutform from "./page/form";
import Addguests from "./page/addGuests";
import SearchInvoice from "./page/searchInvoice";
import Nav from "./layout/nav";
import CompleteGala from "./page/complete";
import SoldOut from "./page/soldOut";
import Axios from "axios";
import environmentVariable from "./envViables";
function App() {
  useEffect(() => {
    getSetting();
  }, []);
  const [appStatus, setAppStatus] = useState("start");
  const getSetting = async () => {
    var baseUrl = environmentVariable.apiURL + "/api/setting";
    const response = await Axios.post(baseUrl, { service: "app" });

    if (response.status == 200) {
      setAppStatus(response.data.status);
    }
  };

  const renderBasedOnStatus = () => {
    switch (appStatus) {
      case "start":
        return <Checkoutform />;
      case "stop":
        return <CompleteGala />;
      case "soldout":
        return <SoldOut />;
      default:
        return <Checkoutform />;
    }
  };
  return (
    <Nav {...{ appStatus: appStatus }}>
      <div className="app">
        <Router>
          <Routes>
            <Route path="*" element={<Home />} />
            <Route exact path="/" element={renderBasedOnStatus()}></Route>
            <Route exact path="/back-up" element={<Checkoutform />}></Route>
            <Route exact path="/update" element={<SearchInvoice />}></Route>
            <Route
              exact
              path="/:email/:invoiceid"
              element={<Addguests />}
            ></Route>
          </Routes>
        </Router>
      </div>
    </Nav>
  );
}

export default App;
